export default {
  state: {
    keepAlive: []
  },
  mutations: {
    setKeepAlive: (state, keepAlive) => {
      state.keepAlive = keepAlive;
    }
  },
  getters: {
    keepAlive: state => state.keepAlive
  }
};
