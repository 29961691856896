<template>
  <div id="app">
    <keep-alive :include="keepAlive">
      <router-view />
    </keep-alive>
    <div v-if="countDownDisabled && !isWebview" class="index-cover-warpper">
      <div class="index-cover-warpper-img">
        <span class="count-down-btn" @click="countDownDisabled = false">
          {{ countDownSeconds }}s <span>|</span> 跳过
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { getProjectTitle } from "@/api/api";
export default {
  data() {
    return {
      isWebview: process.env.VUE_APP_WEBVIEW == 1,
      is_foshan: process.env.VUE_APP_MODE == "foshan",
      // 首页封面倒计时
      countDownSeconds: 3,
      countDownDisabled: false
    };
  },
  onShareAppMessage(){
    return {
      title: '禅心悦佛山市心理健康服务平台'
    }
  },
  onShareTimeline(){},
  computed: {
    keepAlive() {
      return this.$store.getters.keepAlive;
    }
  },
  methods: {
    //验证码倒计时
    countDownFn(seconds = 3) {
      let _this = this;
      seconds = seconds ? seconds : 60;
      this.countDownSeconds = seconds + "";
      let countdown = setInterval(() => {
        if (seconds > 0) {
          _this.countDownSeconds = seconds + "";
          --seconds;
        } else {
          _this.countDownSeconds = 0;
          _this.countDownDisabled = false;
          clearInterval(countdown);
        }
      }, 1000);
    },

    showCover(){
      let vm = this;
      // 如果是佛山+授权登录页面就跳过封面图
      setTimeout(function(){
        if(vm.$route.name!=='fs-login'){
          if (!sessionStorage.getItem("cover")) {
            vm.countDownDisabled = true;
            vm.countDownFn(3);
            sessionStorage.setItem("cover", "cover");
          }
        }
      },300)
    }
    
  },
  created() {
    
    
  },
  watch: {
    // 监听路由
    $route(to, from) {
      // this.showCover();
      // 微信分享 - 允许分享页面
      // console.log('url', window.location.origin+'/fs-logo.png')
      // if (to.name == "Home" || to.name == "invite") {
      //   if (process.env.VUE_APP_BGIMAGE == 1) {
      //     //  微信分享js
      //     let shareModel = {
      //       title: "",
      //       desc: "",
      //       // imgUrl:
      //       //   process.env.VUE_APP_ISLESHAN == 0
      //       //     ? "https://eyan-mhs-resources.oss-cn-beijing.aliyuncs.com/icon/xy360.png"
      //       //     : "https://eyan-mhs-resources.oss-cn-beijing.aliyuncs.com/icon/htxy.jpg",
      //       imgUrl: window.location.origin+'/fs-logo.png',
      //       link: document.URL
      //     };
      //     if (this.$route.query.hasOwnProperty("project_id")) {
      //       getProjectTitle({
      //         project_id: this.$route.query.project_id
      //       }).then(res => {
      //         // shareModel.title = res.data.project_title;
      //         // shareModel.desc = res.data.project_title;
      //         shareModel.title = '禅心悦-佛山市心理健康服务平台';
      //         shareModel.desc = '禅心悦-佛山市心理健康服务平台';
      //         this.wxsdk.init(shareModel);
      //       });
      //     }
      //   } else {
      //     //  微信分享js
      //     let shareModel = {
      //       // title: document.title,
      //       // desc: document.title,
      //       // imgUrl:
      //       //   process.env.VUE_APP_ISLESHAN == 0
      //       //     ? "https://eyan-mhs-resources.oss-cn-beijing.aliyuncs.com/icon/xy360.png"
      //       //     : "https://eyan-mhs-resources.oss-cn-beijing.aliyuncs.com/icon/htxy.jpg",
      //       title: '禅心悦-佛山市心理健康服务平台',
      //       desc: '禅心悦-佛山市心理健康服务平台',
      //       imgUrl: window.location.origin+'/fs-logo.png',
      //       link: document.URL
      //     };
      //     this.wxsdk.init(shareModel);
      //   }
      // } else if (to.path === "/healthyDetail") {
      //   console.log("图文");
      // } else {
      //   // 不允许分享的页面  隐藏分享
      //   let shareModel = {
      //     // title: document.title,
      //     // desc: document.title,
      //     // imgUrl:
      //     //   process.env.VUE_APP_ISLESHAN == 0
      //     //     ? "https://eyan-mhs-resources.oss-cn-beijing.aliyuncs.com/icon/xy360.png"
      //     //     : "https://eyan-mhs-resources.oss-cn-beijing.aliyuncs.com/icon/htxy.jpg",
      //     title: '禅心悦-佛山市心理健康服务平台',
      //     desc: '禅心悦-佛山市心理健康服务平台',
      //     imgUrl: window.location.origin+'/fs-logo.png',
      //     link: document.URL
      //   };
      //   this.wxsdk.init(shareModel);
      //   // 通过下面这个API隐藏右上角分享按钮
      //   if (typeof WeixinJSBridge == "undefined") {
      //     if (document.addEventListener) {
      //       document.addEventListener(
      //         "WeixinJSBridgeReady",
      //         onBridgeReady,
      //         false
      //       );
      //     } else if (document.attachEvent) {
      //       document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
      //       document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
      //     }
      //   } else {
      //     onBridgeReady();
      //   }
      //   function onBridgeReady() {
      //     // WeixinJSBridge.call('hideOptionMenu');
      //   }
      // }
    }
  },
  updated() {},
  mounted() {
    // alert(document.body.clientWidth,"宽度")
    // 是否屏蔽console  上线可开启
    // if (!global.consoleSwitch) {
    //   console.log = () => {};
    // }
    //  let shareModel = {
    //   title: document.title,
    //   desc: document.title,
    //   imgUrl: process.env.VUE_APP_ISLESHAN == 0 ? "https://eyan-mhs-resources.oss-cn-beijing.aliyuncs.com/icon/xy360.png" : 'https://eyan-mhs-resources.oss-cn-beijing.aliyuncs.com/icon/htxy.jpg',
    //   link: document.URL,
    // };
    // this.wxsdk.init(shareModel);

    
    
    
  }
};
</script>
<style lang="scss">
//  滚动条隐藏
::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}
$rem: 37.5;
// 公共样式
@import "../src/assets/css/common.css";
html,
body {
  height: 100%;
}
#app {
  height: 100%;
  overflow: hidden;
}
// 强制重置vant样式  UI要求
.hospital {
  .van-dropdown-menu__bar {
    box-shadow: none !important;
    height: 30px !important;
    background: rgba(245, 247, 250, 1) !important;
    border-radius: 5px !important;
  }
}
@media screen and (min-width: 1025px) {
  //min-width:1024px
  #app {
    max-width: 560px;
    margin: 0 auto;
    background: #fff;
    border: 1px solid #f5f5f5;
    // box-shadow: 2px 6px 10px 6px rgba(0, 0, 0, 0.03);
  }
  .newHealthList {
    max-width: 560px;
    left: auto;
  }
  .record-container,
  .infor-out {
    max-width: 560px;
  }
  .information .btm,
  .doctorList .course-nav {
    width: 560px !important;
  }
  .edit-record-container {
    max-width: 560px;
  }
  .doctorList .video-list .list .item .video-t-new {
    width: 92% !important;
  }
  .healthy .course-nav .van-tabs__line {
    bottom: 12px !important;
  }
  .van-dropdown-item {
    left: 32.7%;
    max-width: 560px;
  }
  .homePage .yidao {
    right: 34% !important;
  }
  .bgTopInner {
    max-width: 527px;
  }
  .signout {
    max-width: 527px;
  }
  .questionList .searchBg,
  .question-area-list {
    max-width: 560px;
  }
  .questionList .searchBg .searchFor {
    max-width: 560px;
  }
  .questionList .searchList {
    max-width: 560px;
  }
  .headerXp,
  .header {
    max-width: 560px;
  }
  .van-tab {
    line-height: 1 !important;
  }
  .popupLayer {
    max-width: 392px;
  }
  .van-radio__label {
    line-height: 28px;
  }
  .van-popup--bottom {
    bottom: 0;
    left: auto;
    width: 100%;
    max-width: 560px;
    margin: 0 auto;
  }
  .footerXp {
    max-width: 560px;
  }
  .circleWP .circleXp .cicleIn {
    width: 140px !important;
    font-size: 24px !important;
    left: 46%;
    left: 37% !important;
    top: 37% !important;
  }
  .videoInfor .titleSmall:before {
    height: 16px !important;
  }
  .nameBtm {
    font-size: 16px !important;
  }
  .bindpage /deep/ .van-field__label,
  .inforHealth /deep/ .van-field__label {
    width: 120px !important;
  }
  .botBtm .van-tab__text {
    line-height: initial;
  }
  .nav-cont,
  #addrecord .popupTitle {
    max-width: 560px;
  }
  .lineContent {
    height: 330px !important;
  }
  .ai-guide {
    min-height: 100vh;
  }
  .newAdd {
    max-width: 560px;
  }
  .signout {
    max-width: 560px;
  }
}

/* 首页cover */
.index-cover-warpper {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  .index-cover-warpper-img {
    width: 100%;
    max-width: 560px;
    margin: 0 auto;
    height: 100%;
    position: relative;
    background: #212eae url("./assets/images/index-cover.png") center top
      no-repeat;
    background-size: 100% 98%;
    .count-down-btn {
      position: absolute;
      right: 30rem / $rem;
      top: 30rem / $rem;
      display: inline-block;
      background-color: rgba(0, 0, 0, 0.7);
      color: #e1e1e1;
      font-size: 14rem / $rem;
      padding: 4rem / $rem 12rem / $rem;
      border-radius: 20rem / $rem;
      span {
        margin: 0 5rem / $rem;
      }
    }
  }
}
</style>
