import axios from "axios";
import config from "./config";
import router from "../router";
import store from "@/store";
import qs from "qs";
import showStatus from "./status";
import { Dialog } from "vant";
import { Toast } from "vant";
import {
  getUrlParam,
  getCookie,
  getFiAccessToken, //token
  delCookie,
  getFiSign,
} from "../utils/mUtils";

let oAuth = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL + "/api/v1/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded",
  },
  transformRequest: [
    function (data) {
      //由于使用的form-data传数据所以要格式化
      delete data.Authorization;
      data = qs.stringify(data);
      return data;
    },
  ],
});

let timer = {};

// 请求开始
oAuth.interceptors.request.use(
  (config) => {
    clearTimeout(timer);
    timer = setTimeout(function () {
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "请稍后...",
      });
    }, 800);
    config.headers.Authorization = "Bearer " + getFiAccessToken();
    const params = config.method === "post" ? "data" : "params";
    config[params] = config[params] ? config[params] : {};
    Object.assign(config[params], {
      // login_token: localStorage.getItem("mhsh5_login_token") || "",
      //h5使用sessionStorage  app使用localStorage
      login_token:
        sessionStorage.getItem("mhsh5_login_token") ||
        localStorage.getItem("mhsh5_login_token") ||
        "",
    });
    config[params].sign = getFiSign(config[params]);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 请求结束
oAuth.interceptors.response.use(
  (response) => {
    // 手动清除 Toast
    clearTimeout(timer);
    Toast.clear();
    // dosomething
    if (response.data.code === 600) {
      if (
        location.href.indexOf("/invite?") < 0 &&
        location.href.indexOf("/doctor-comment?") < 0
      ) {
        delCookie("mhs_access_token");
        sessionStorage.removeItem('mhsh5_login_token')
        //除了团测分享的跳
        router.push({
          name: "login",
          query: {
            project_id: getUrlParam("project_id"),
            scale_id: getUrlParam("scale_id") || "",
          },
        });
      }
    } else if(response.data.code == 110) {
      delCookie("mhs_access_token");
      sessionStorage.removeItem('mhsh5_login_token')
      router.push({
        name: "login",
        query: {
          project_id: getUrlParam("project_id"),
        },
      });
    }
    return response;
  },
  (error) => {
    if (error.response) {
      // 手动清除 Toast
      clearTimeout(timer);
      Toast.clear();
      // Dialog({ message: localStorage.getItem("CLIENT_SECRET")} )
      if (error.response.statusText === "Unauthorized") {
        delCookie("mhs_access_token");
        sessionStorage.removeItem('mhsh5_login_token')
      }
      switch (error.response.status) {
        case 401:
          // 只有在当前路由不是登录页面才跳转
          if (error.response.data.code === 1100 || error.response.data.code == 110) {
            delCookie("mhs_access_token");
            sessionStorage.removeItem('mhsh5_login_token')
            router.push({
              name: "login",
              query: {
                project_id: getUrlParam("project_id"),
                scale_id: getUrlParam("scale_id") || "",
              },
            });
          } else if (error.response.data.code === 601) {
          } else {
            // Toast.fail('是吗'+error.response.data.msg);
          }
      }
      return Promise.reject(error);
    }
  }
);
// 超时时间
oAuth.defaults.timeout = 50000;

//测试
export const oAuthTest = (params) => {
  return oAuth.post("oAuthTest", params, config).then((res) => res.data);
};

//获取量表问卷信息
export const getEvalCrf = (params) => {
  return oAuth
    .post("EvalManage/getEvalCrf", params, config)
    .then((res) => res.data);
};
export const filloutOneQuestion = (params) => {
  return oAuth
    .post("Questionnaire/filloutOneQuestion", params, config)
    .then((res) => res.data);
};
export const filloutOneQuestionnaire = (params) => {
  return oAuth
    .post("Questionnaire/filloutOneQuestionnaire", params, config)
    .then((res) => res.data);
};
export const getScaleList = (params) => {
  return oAuth
    .post("Questionnaire/getScaleList", params, config)
    .then((res) => res.data);
};
export const createScaleAnswerId = (params) => {
  return oAuth
    .post("Questionnaire/createScaleAnswerId", params, config)
    .then((res) => res.data);
};
export const getScaleQuestion = (params) => {
  return oAuth
    .post("Questionnaire/getScaleQuestion", params, config)
    .then((res) => res.data);
};
export const getAllLabelList = (params) => {
  return oAuth
    .post("Label/getAllLabelList", params, config)
    .then((res) => res.data);
};
export const getLabel = (params) => {
  return oAuth.post("Project/getLabel", params, config).then((res) => res.data);
};
export const getScaleAnswer = (params) => {
  return oAuth
    .post("Questionnaire/getScaleAnswer", params, config)
    .then((res) => res.data);
};
export const getSelfScaleAnswer = (params) => {
  return oAuth
    .post("Questionnaire/getSelfScaleAnswer", params, config)
    .then((res) => res.data);
};
export const recordBeginTime = (params) => {
  return oAuth
    .post("Questionnaire/recordBeginTime", params, config)
    .then((res) => res.data);
};
export const unifiedorder = (params) => {
  return oAuth
    .post("Order/unifiedorder", params, config)
    .then((res) => res.data);
};
export const addOrder = (params) => {
  return oAuth.post("Order/addOrder", params, config).then((res) => res.data);
};
export const orderquery = (params) => {
  return oAuth.post("Order/orderquery", params, config).then((res) => res.data);
};
export const getTeamInfoByQrCode = (params) => {
  return oAuth
    .post("scale/getTeamInfoByQrCode", params, config)
    .then((res) => res.data);
};
export const filloutUserInfo = (params) => {
  return oAuth
    .post("Questionnaire/filloutUserInfo", params, config)
    .then((res) => res.data);
};
export const getUserInfoQuestion = (params) => {
  return oAuth
    .post("Questionnaire/getUserInfoQuestion", params, config)
    .then((res) => res.data);
};
export const isTeamUser = (params) => {
  return oAuth
    .post("Questionnaire/isTeamUser", params, config)
    .then((res) => res.data);
};
export const getUserNeedFilloutInfo = (params) => {
  return oAuth
    .post("Questionnaire/getUserNeedFilloutInfo", params, config)
    .then((res) => res.data);
};
export const getNotFinishEvaluationList = (params) => {
  return oAuth
    .post("Questionnaire/getNotFinishEvaluationList", params, config)
    .then((res) => res.data);
};
export const getUserParticipateAnswerList = (params) => {
  return oAuth
    .post("Questionnaire/getUserParticipateAnswerList", params, config)
    .then((res) => res.data);
};
export const getScaleTeamUserInfo = (params) => {
  return oAuth
    .post("EvalTeam/getScaleTeamUserInfo", params, config)
    .then((res) => res.data);
};
export const getScaleClass = (params) => {
  return oAuth
    .post("investigation/getScaleClass", params, config)
    .then((res) => res.data);
};
export const appConfig = (params) => {
  return oAuth.post("appConfig", params, config).then((res) => res.data);
};
export const checkAnswerContinue = (params) => {
  return oAuth
    .post("Questionnaire/checkAnswerContinue", params, config)
    .then((res) => res.data);
};
export const getDoctorList = (params) => {
  return oAuth
    .post("Consultation/getDoctorList", params, config)
    .then((res) => res.data);
};
//获取量表答案信息
export const getScaleAnswerInfo = (params) => {
  return oAuth
    .post("EvalManage/getScaleAnswerInfo", params, config)
    .then((res) => res.data);
};
//量表设置
export const getScaleSet = (params) => {
  return oAuth
    .post("EvalManage/getScaleSet", params, config)
    .then((res) => res.data);
};

//验证码获取
export const sendSmsCode = (params) => {
  return oAuth.post("Sms/sendSmsCode", params, config).then((res) => res.data);
};
//登录
export const login = (params) => {
  return oAuth.post("login/loginService", params, config).then((res) => res.data);
  // return oAuth.post("patient/login", params, config).then((res) => res.data);
};
//验证码登录
export const phoneloginCode = (params) => {
  return oAuth
    .post("patient/phoneloginCode", params, config)
    .then((res) => res.data);
};
//密码重置
export const resetPasswordPhone = (params) => {
  return oAuth
    .post("patient/resetPasswordPhone", params, config)
    .then((res) => res.data);
};
//注册
export const patientRegistered = (params) => {
  return oAuth
    .post("patient/patientRegistered", params, config)
    .then((res) => res.data);
};
//个人信息
export const getPatientInfo = (params) => {
  return oAuth
    .post("patient/getPatientInfo", params, config)
    .then((res) => res.data);
};
//测评列表
export const getPatientOwnScaleAnswer = (params) => {
  return oAuth
    .post("EvalManage/getPatientOwnScaleAnswer", params, config)
    .then((res) => res.data);
};
//获取用户字典信息
export const getDictList = (params) => {
  return oAuth
    .post("patient/getDictList", params, config)
    .then((res) => res.data);
};
//机构列表
export const getResearchCenterSelectList = (params) => {
  return oAuth
    .post("ResearchCenter/getResearchCenterSelectList", params, config)
    .then((res) => res.data);
};
//编辑个人信息
export const editUserPatientInfo = (params) => {
  return oAuth
    .post("patient/editUserPatientInfo", params, config)
    .then((res) => res.data);
};
//有未查看的小红点
export const getPatientOwnEvalResultNotViewState = (params) => {
  return oAuth
    .post("EvalManage/getPatientOwnEvalResultNotViewState", params, config)
    .then((res) => res.data);
};
//
export const viewPatientOwnEvalResult = (params) => {
  return oAuth
    .post("EvalManage/viewPatientOwnEvalResult", params, config)
    .then((res) => res.data);
};
//我的订单
export const ownOrderManagement = (params) => {
  return oAuth
    .post("OrderManage/ownOrderManagement", params, config)
    .then((res) => res.data);
};
//删除我的订单
export const delOwnOrder = (params) => {
  return oAuth
    .post("OrderManage/delOwnOrder", params, config)
    .then((res) => res.data);
};
//量表表单基本信息字段
export const getPatientBaseInfoField = (params) => {
  return oAuth
    .post("EvalBaseInfo/getPatientBaseInfoField", params, config)
    .then((res) => res.data);
};
//取消我的订单
export const cancelOrder = (params) => {
  return oAuth
    .post("OrderManage/cancelOrder", params, config)
    .then((res) => res.data);
};
//个人信息回显 EvalManage/getUserLastAnswerBaseInfo
export const getUserLastAnswerBaseInfo = (params) => {
  return oAuth
    .post("EvalManage/getUserLastAnswerBaseInfo", params, config)
    .then((res) => res.data);
};
//团测详情
export const getScaleTeamDetail = (params) => {
  return oAuth
    .post("EvalTeam/getScaleTeamDetail", params, config)
    .then((res) => res.data);
};
//判断是否需修改密码 patient/needChangePassword
export const needChangePassword = (params) => {
  return oAuth
    .post("patient/needChangePassword", params, config)
    .then((res) => res.data);
};
//首次登录后修改密码
export const changePassword = (params) => {
  return oAuth
    .post("patient/changePassword", params, config)
    .then((res) => res.data);
};
//密码修改
export const changePasswordRepeat = (params) => {
  return oAuth
    .post("user/changePassword", params, config)
    .then((res) => res.data);
};
// 设置手机号注册的没有密码的
export const userSetPassword = (params) => {
  return oAuth
    .post("users/userSetPassword", params, config)
    .then((res) => res.data);
};
//用户是否有密码，是否显示要设置密码
export const getUserIsSetPassword = (params) => {
  return oAuth
    .post("users/getUserIsSetPassword", params, config)
    .then((res) => res.data);
};
//绑定手机号
export const bindPhoneNumber = (params) => {
  return oAuth
    .post("patient/bindPhoneNumber", params, config)
    .then((res) => res.data);
};
//判断用户当前团测是否有已提交的答案
export const getNowTeamOwnAnswer = (params) => {
  return oAuth
    .post("Questionnaire/getNowTeamOwnAnswer", params, config)
    .then((res) => res.data);
};
//验证验证码有效性 Sms/verificationSmsCode
export const verificationSmsCode = (params) => {
  return oAuth
    .post("Sms/verificationSmsCode", params, config)
    .then((res) => res.data);
};
//
export const getAnswerBeforeInfo = (params) => {
  return oAuth
    .post("EvalManage/getAnswerBeforeInfo", params, config)
    .then((res) => res.data);
};

//【健康知识】获取分类列表
export const getClassificationList = (params) => {
  return oAuth
    .post("video/getClassificationList", params, config)
    .then((res) => res.data);
};

//【健康知识】h5获取健康知识视频
export const getH5HomeVideoList = (params) => {
  return oAuth
    .post("video/getH5HomeVideoList", params, config)
    .then((res) => res.data);
};

//【健康知识】报告页获取视频列表
export const getReportVideoList = (params) => {
  return oAuth
    .post("video/getReportVideoList", params, config)
    .then((res) => res.data);
};

//【健康知识】报告页获取音频列表
export const getReportAudioList = (params) => {
  return oAuth
    .post("audio/getReportAudioList", params, config)
    .then((res) => res.data);
};

//【健康知识】报告页获取图文列表
export const getReportDocumentList = (params) => {
  return oAuth
    .post("document/getReportDocumentList", params, config)
    .then((res) => res.data);
};

//【健康知识】h5获取健康知识图文
export const getH5HomeDocumentList = (params) => {
  return oAuth
    .post("document/getH5HomeDocumentList", params, config)
    .then((res) => res.data);
};

//【健康知识】h5获取健康知识音频
export const getH5HomeAudioList = (params) => {
  return oAuth
    .post("audio/getH5HomeAudioList", params, config)
    .then((res) => res.data);
};

//【健康知识】h5获取健康知识直播
export const getH5LiveVideoList = (params) => {
  return oAuth
    .post("live/getH5HomeVideoList", params, config)
    .then((res) => res.data);
};

//【健康知识】获取直播详情
export const getVideoForLiveInfo = (params) => {
  return oAuth
    .post("live/getVideoForLiveInfo", params, config)
    .then((res) => res.data);
};

//【健康知识】获取视频图文详情
export const getVideoForDemandInfo = (params) => {
  return oAuth
    .post("video/getVideoForDemandInfo", params, config)
    .then((res) => res.data);
};

//【健康知识统计】统计浏览量
export const statisticNumber = (params) => {
  return oAuth
    .post("video/statisticNumber", params, config)
    .then((res) => res.data);
};
//【健康知识统计】统计直播浏览量
export const liveStatisticNumber = (params) => {
  return oAuth
    .post("live/statisticNumber", params, config)
    .then((res) => res.data);
};

//【健康知识】获取浏览记录
export const getBrowseHistory = (params) => {
  return oAuth
    .post("video/getBrowseHistory", params, config)
    .then((res) => res.data);
};

//【健康知识统计】统计浏览时长
export const statisticTime = (params) => {
  return oAuth
    .post("video/statisticTime", params, config)
    .then((res) => res.data);
};
//【健康知识统计】统计直播浏览时长
export const liveStatisticTime = (params) => {
  return oAuth
    .post("live/statisticTime", params, config)
    .then((res) => res.data);
};

//个人测评机构下拉
export const getResearchCenterDropDown = (params) => {
  return oAuth
    .post("Common/getResearchCenterDropDown", params, config)
    .then((res) => res.data);
};
//
export const getHospitalDoctorLocated = (params) => {
  return oAuth
    .post("Consultation/getHospitalDoctorLocated", params, config)
    .then((res) => res.data);
};
//创建健康档案
export const AddOwnHealthRecords = (params) => {
  return oAuth
    .post("HealthRecords/AddOwnHealthRecords", params, config)
    .then((res) => res.data);
};
//创建健康档案
export const editHealthRecords = (params) => {
  return oAuth
    .post("HealthRecords/editHealthRecords", params, config)
    .then((res) => res.data);
};
export const getHealthRecordsDetail = (params) => {
  return oAuth
    .post("HealthRecords/getHealthRecordsDetail", params, config)
    .then((res) => res.data);
};
export const getAppointmentHealthRecordsDetail = (params) => {
  return oAuth
    .post("healthRecords/getAppointmentHealthRecordsDetail", params, config)
    .then((res) => res.data);
};
//创建档案表单添加
export const getHealthRecordsBaseInfo = (params) => {
  return oAuth
    .post("HealthRecords/getHealthRecordsBaseInfo", params, config)
    .then((res) => res.data);
};
//用户是否创建过档案
export const IsHealthRecordsH5 = (params) => {
  return oAuth
    .post("HealthRecords/IsHealthRecordsH5", params, config)
    .then((res) => res.data);
};
//档案详情-H5答题回显
export const getHealthRecordsDetailH5 = (params) => {
  return oAuth
    .post("HealthRecords/getHealthRecordsDetailH5", params, config)
    .then((res) => res.data);
};
//档案详情-测评报告
export const getHealthRecordsAnswer = (params) => {
  return oAuth
    .post("HealthRecords/getHealthRecordsAnswer", params, config)
    .then((res) => res.data);
};
//档案详情-测评分析
export const getHealthRecordsScaleAnalysis = (params) => {
  return oAuth
    .post("HealthRecords/getHealthRecordsScaleAnalysis", params, config)
    .then((res) => res.data);
};
//用户端获取医生列表 Consultation/getDoctorsConsultList
export const getDoctorsConsultList = (params) => {
  return oAuth
    .post("Consultation/getDoctorsConsultList", params, config)
    .then((res) => res.data);
};
//用户端首页
export const homeConsult = (params) => {
  return oAuth
    .post("Consultation/homeConsult", params, config)
    .then((res) => res.data);
};
//获取团测设置简单版
export const getScaleTeamSet = (params) => {
  return oAuth
    .post("EvalTeam/getScaleTeamSet", params, config)
    .then((res) => res.data);
};
//获取科室下拉
export const getDepartmentDropDown = (params) => {
  return oAuth
    .post("Common/getDepartmentDropDown", params, config)
    .then((res) => res.data);
};
//获取职称下拉
export const getPositionalDropDown = (params) => {
  return oAuth
    .post("Common/getPositionalDropDown", params, config)
    .then((res) => res.data);
};
//获取擅长下拉
export const getGoodAtDropDown = (params) => {
  return oAuth
    .post("Common/getGoodAtDropDown", params, config)
    .then((res) => res.data);
};
//健康档案列表
export const getOwnAllHealthRecords = (params) => {
  return oAuth
    .post("HealthRecords/getOwnAllHealthRecords", params, config)
    .then((res) => res.data);
};
//更新健康档案状态
export const editHealthRecordsDefault = (params) => {
  return oAuth
    .post("HealthRecords/editHealthRecordsDefault", params, config)
    .then((res) => res.data);
};
//登录、退出埋点
export const addPublicLog = (params) => {
  return oAuth.post("log/addPublicLog", params, config).then((res) => res.data);
};
//添加项目用户邀请用户健康档案
export const addUserInviteHealthRecords = (params) => {
  return oAuth
    .post("HealthRecords/addUserInviteHealthRecords", params, config)
    .then((res) => res.data);
};
//getResearchEvaluationCenterDropDown
export const getResearchEvaluationCenterDropDown = (params) => {
  return oAuth
    .post("Common/getResearchEvaluationCenterDropDown", params, config)
    .then((res) => res.data);
};
//公告栏
export const noticeList = (params) => {
  return oAuth.post("notice/list", params, config).then((res) => res.data);
};
//公告栏详情
export const noticeInfo = (params) => {
  return oAuth.post("notice/info", params, config).then((res) => res.data);
};
//首页项目名称获取
export const getProjectTitle = (params) => {
  return oAuth
    .post("Project/getProjectTitle", params, config)
    .then((res) => res.data);
};
//组织机构树
export const getOrganizationTree = (params) => {
  return oAuth
    .post("Organization/getOrganizationTree", params, config)
    .then((res) => res.data);
};
export const getHotlineContent = (params) => {
  return oAuth
    .post("Project/getHotlineContent", params, config)
    .then((res) => res.data);
};
//获取首页展示应用
export const getApplicationList = (params) => {
  return oAuth
    .post("Application/getApplicationList", params, config)
    .then((res) => res.data);
};
//删除量表中的答案
export const delOngoingScaleAnswer = (params) => {
  return oAuth
    .post("Questionnaire/delOngoingScaleAnswer", params, config)
    .then((res) => res.data);
};
//获取测评说明
export const getReportDescription = (params) => {
  return oAuth
    .post("Project/getReportDescription", params, config)
    .then((res) => res.data);
};
//取消导入强制设置密码
export const cancelChangePasswordTip = (params) => {
  return oAuth
    .post("users/cancelChangePasswordTip", params, config)
    .then((res) => res.data);
};
//获取设置参数
export const getProjectDetails = (params) => {
  return oAuth
    .post("Project/getProjectDetails", params, config)
    .then((res) => res.data);
};
//检查是否有健康卡
export const checkHealthCardExist = (params) => {
  return oAuth
    .post("ehc/checkHealthCardExist", params, config)
    .then((res) => res.data);
};
//跳转健康卡申请页面
export const healthCardApplyLink = (params) => {
  return oAuth
    .post("ehc/fs/healthCardApplyLink", params, config)
    .then((res) => res.data);
};
//获取电子健康卡二维码
export const getHealthCardQr = (params) => {
  return oAuth
    .post("ehc/fs/getHealthCardQr", params, config)
    .then((res) => res.data);
};
//获取验证码
export const isUserPresence = (params) => {
  return oAuth
    .post("user/isUserPresence", params, config)
    .then((res) => res.data);
};
//登录密码验证规则
export const passwordValidationRules = (params) => {
  return oAuth
    .post("user/passwordValidationRules", params, config)
    .then((res) => res.data);
};
//获取用药列表
export const getDiagnosis = (params) => {
  return oAuth
    .post("Consultation/getDiagnosis", params, config)
    .then((res) => res.data);
};
//开启用药推送
export const onPushPills = (params) => {
  return oAuth
    .post("Consultation/onPushPills", params, config)
    .then((res) => res.data);
};
//关闭用药推送
export const offPushPills = (params) => {
  return oAuth
    .post("Consultation/offPushPills", params, config)
    .then((res) => res.data);
};
//获取药品详情
export const getPills = (params) => {
  return oAuth
    .post("Consultation/getPills", params, config)
    .then((res) => res.data);
};
//获取不良反应
export const getPillsAdr = (params) => {
  return oAuth
    .post("Consultation/getPillsAdr", params, config)
    .then((res) => res.data);
};

//提交不良反应
export const reportPills = (params) => {
  return oAuth
    .post("Consultation/reportPills", params, config)
    .then((res) => res.data);
};

//添加健康知识评论
export const addComment = (params) => {
  return oAuth.post("video/addComment", params, config).then((res) => res.data);
};
//获取评论
export const getComment = (params) => {
  return oAuth.post("video/getComment", params, config).then((res) => res.data);
};

export const getDoctorCommentSwitch = (params) => {
  return oAuth
    .post("Project/getDoctorCommentSwitch", params, config)
    .then((res) => res.data);
};

//添加咨询评价
export const addConsultationComment = (params) => {
  return oAuth
    .post("consult/commentMyConsult", params, config)
    .then((res) => res.data);
};

export const getOrganizationInfo = (params) => {
  return oAuth
    .post("Organization/getOrganizationInfo", params, config)
    .then((res) => res.data);
};

export const addDiagnosis = (params) => {
  return oAuth
    .post("Consultation/addDiagnosis", params, config)
    .then((res) => res.data);
};

export const WxLogin = (params) => {
  return oAuth.post("Wx/WxLogin", params, config).then((res) => res.data);
};
//微信授权登陆
export const WxCodeLogin = (params) => {
  return oAuth.post("WxCode/login", params, config).then((res) => res.data);
};
//微信授权登陆绑定手机
export const WxCodeBindPhone = (params) => {
  return oAuth.post("WxCode/bindPhone", params, config).then((res) => res.data);
};

//获取医生详情
export const getDoctorsDetail = (params) => {
  return oAuth
    .post("Consultation/getDoctorsDetail", params, config)
    .then((res) => res.data);
};
//获取医生评论
export const getDoctorsComment = (params) => {
  return oAuth
    .post("Consultation/getComment", params, config)
    .then((res) => res.data);
};
//获取医生可预约时间列表
export const getConsultSchedule = (params) => {
  return oAuth
    .post("consultation/getConsultSchedule", params, config)
    .then((res) => res.data);
};
//提交预约申请
export const addProjectAppointment = (params) => {
  return oAuth
    .post("Consultation/addProjectAppointment", params, config)
    .then((res) => res.data);
};
//我的咨询
export const myConsultPatient = (params) => {
  return oAuth
    .post("Consultation/myConsultPatient", params, config)
    .then((res) => res.data);
};
//取消咨询
export const cancelAppointment = (params) => {
  return oAuth
    .post("Consultation/cancelAppointment", params, config)
    .then((res) => res.data);
};
//咨询详情
export const getAppointmentDetail = (params) => {
  return oAuth
    .post("Consultation/getAppointmentDetail", params, config)
    .then((res) => res.data);
};

//融云用户信息
export const geRongtUser = (params) => {
  return oAuth.post("Rong/getUser", params, config).then((res) => res.data);
};
//通过ID融云用户信息
export const geRongtUserById = (params) => {
  return oAuth.post("Rong/getUserById", params, config).then((res) => res.data);
};
//融云发送消息
export const RongSendMessage = (params) => {
  return oAuth.post("Rong/sendMessage", params, config).then((res) => res.data);
};
//添加咨询记录
export const addConsultationRecord = (params) => {
  return oAuth
    .post("Consultation/addConsultationRecord", params, config)
    .then((res) => res.data);
};

//上传文件
export const crfUploadFile = (params) => {
  return oAuth
    .post("crfUploadFile", params, {
      baseURL: process.env.VUE_APP_BASE_URL + "/api/v1/",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      transformRequest: [
        function (data) {
          //由于使用的form-data传数据所以要格式化
          delete data.Authorization;
          //  data = qs.stringify(data);
          return data;
        },
      ],
    })
    .then((res) => res.data);
};

// 佛山+授权登录禅心悦
export const fsLogin = (params) => {
  return oAuth.post("fs/fsLogin", params, config).then((res) => res.data);
};

//咨询类型列表
export const getConsultationTypeList = (params) => {
  return oAuth
    .post("Dictionary/getConsultationTypeList", params, config)
    .then((res) => res.data);
};
//获取咨询指定天数值班人员的数量
export const getDutySchedulePersonQuantity = (params) => {
  return oAuth
    .post("consult/getDutySchedulePersonQuantity", params, config)
    .then((res) => res.data);
};
export const announcementPaging = (params) => {
  return oAuth
    .post("announcement/announcementPaging", params, config)
    .then((res) => res.data);
};
export const submitAnswer = (params) => {
  return oAuth
    .post("yform/submitAnswer", params, config)
    .then((res) => res.data);
};
