import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// 解决报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// 针对 push 方法
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// 针对 replace 方法
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/home/index.vue"), //@/views/Home.vue
    meta: {
      active: "home-page",
      title: "\u200E",
      keepAlive: true
    }
  },
  {
    path: "/team",
    name: "team",
    component: () => import("@/views/team/team.vue"),
    meta: { title: "团体测评" }
  },
  {
    path: "/phone",
    name: "phone",
    component: () => import("@/views/phone/phone.vue"),
    meta: { title: "心理服务" }
  },
  {
    path: "/course",
    name: "course",
    component: () => import("@/views/video/course.vue"),
    meta: { title: "心理课程" }
  },
  {
    path: "/courseList",
    name: "courseList",
    component: () => import("@/views/video/courseList.vue"),
    meta: { title: "心理课程" }
  },
  {
    path: "/videoHistory",
    name: "videoHistory",
    component: () => import("@/views/video/history.vue"),
    meta: { title: "观看历史" }
  },
  {
    //写的
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/components/login.vue"),
    meta: { title: "登录" }
  },
  {
    path: "/wxlogin",
    name: "Wxlogin",
    component: () => import("@/components/wxlogin.vue"),
    meta: { title: "微信登录" }
  },
  {
    path: "/fs-login",
    name: "fs-login",
    component: () => import("@/components/fs-login.vue"),
    meta: { title: "微信登录" }
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("@/views/wx/privacy.vue"),
    meta: { title: "隐私协议" }
  },
  {
    path: "/sms",
    name: "sms",
    component: () => import("@/views/wx/sms.vue"),
    meta: { title: "为什么收不到验证码？" }
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () => import("@/views/wx/agreement.vue"),
    meta: { title: "用户协议" }
  },
  {
    //写的
    path: "/register",
    name: "register",
    component: () => import("@/components/register.vue"),
    meta: { title: "账号注册" }
  },
  {
    path: "/courseList",
    name: "courseList",
    component: () => import("@/views/video/courseList.vue"),
    meta: { title: "心理课程" }
  },
  {
    path: "/videoHistory",
    name: "videoHistory",
    component: () => import("@/views/video/history.vue"),
    meta: { title: "观看历史" }
  },
  {
    //写的
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/components/login.vue"),
    meta: { title: "登录" }
  },
  {
    //写的
    path: "/register",
    name: "register",
    component: () => import("@/components/register.vue"),
    meta: { title: "账号注册" }
  },
  {
    //写的
    path: "/forget-password",
    name: "forget-password",
    component: () => import("@/components/forgetpassword.vue"),
    meta: { title: "重置密码" }
  },
  {
    //写的
    path: "/password-reset",
    name: "password-reset",
    component: () => import("@/views/personal/passwordreset.vue"),
    meta: { title: "重置密码" }
  },
  {
    //绑定
    path: "/bind-page",
    name: "bind-page",
    component: () => import("@/views/personal/bindpage.vue"),
    meta: { title: "更换绑定手机号" }
  },
  {
    //写的
    path: "/home",
    name: "home",
    component: () => import("@/views/personal/home.vue"),
    meta: { title: "我的" }
  },
  {
    path: "/error",
    name: "error",
    component: () => import("@/views/personal/error"),
    meta: { title: "提示页" } //登录后完善信息
  },
  {
    //写的
    path: "/information",
    name: "information",
    component: () => import("@/views/personal/information.vue"),
    meta: { title: "个人信息" }
  },
  {
    //写的
    path: "/my-assess",
    name: "my-assess",
    component: () => import("@/views/personal/myassess.vue"),
    meta: { title: "我的测评" }
  },
  {
    //写的
    path: "/my-report",
    name: "my-report",
    component: () => import("@/views/personal/myreport.vue"),
    meta: { title: "我的报告" }
  },
  {
    //写的
    path: "/report",
    name: "report",
    component: () => import("@/views/personal/report.vue"),
    meta: { title: "测评结果" }
  },
  {//定制化个人报告
    path: '/custom-report',
    name: 'custom-report',
    component: () => import('@/views/customreport/custom-report.vue'),
    meta: { title: '测评结果' }
  },
  {
    //写的
    path: "/footer",
    name: "footer",
    component: () => import("@/components/footer.vue"),
    meta: { title: "测评结果" }
  },
  {
    //二期
    path: "/order",
    name: "order",
    component: () => import("@/views/personal/order.vue"),
    meta: { title: "我的订单" }
  },
  {
    //二期
    path: "/assess",
    name: "assess",
    component: () => import("@/views/personal/assess.vue"),
    meta: { title: "我的测评券" }
  },
  {
    path: "/question",
    name: "question",
    component: () => import("@/views/question/question.vue"),
    meta: { title: "量表测评" }
  },
  {
    path: "/survey",
    name: "survey",
    component: () => import("@/views/question/survey.vue"),
    meta: { title: "问卷测评" }
  },
  {
    path: "/videoList",
    name: "videoList",
    component: () => import("@/views/doctor/videoList.vue"),
    meta: { title: "医生列表" }
  },
  {
    path: "/chatList",
    name: "chatList",
    component: () => import("@/views/doctor/chatList.vue"),
    meta: { title: "医生列表" }
  },
  {
    path: "/healthyList",
    name: "healthyList",
    component: () => import("@/views/healthy/index.vue"),
    meta: { title: "健康知识列表", keepAlive: true }
  },
  {
    path: "/healthyDetail",
    name: "healthyDetail",
    component: () => import("@/views/healthy/detail.vue"),
    meta: { title: "健康知识详情" }
  },
  {
    path: "/liveList",
    name: "liveList",
    component: () => import("@/views/live/index.vue"),
    meta: { title: "直播列表" }
  },
  {
    path: "/liveDetail",
    name: "liveDetail",
    component: () => import("@/views/live/detail.vue"),
    meta: { title: "直播详情" }
  },
  {
    path: "/app/doctor",
    name: "doctor",
    component: () => import("@/views/app/doctor.vue"),
    meta: { title: "选择医生" }
  },
  {
    path: "/appDown",
    name: "appDown",
    component: () => import("@/views/app/appDown.vue"),
    meta: { title: "选择医生" }
  },
  {
    path: "/team",
    name: "team",
    component: () => import("@/views/doctor/team.vue"),
    meta: { title: "团测" }
  },
  {
    path: "/questionName",
    name: "questionName",
    component: () => import("@/views/question/questionName.vue"),
    meta: { title: "测评登记" }
  },
  {
    path: "/questionList",
    name: "questionList",
    component: () => import("@/views/question/questionList.vue"),
    meta: { title: "测评选择" }
  },
  {
    path: "/questionPay",
    name: "questionPay",
    component: () => import("@/views/question/questionPay.vue"),
    meta: { title: "测评支付" }
  },
  {
    path: "/questionDetails",
    name: "questionDetails",
    component: () => import("@/views/question/questionDetails.vue"),
    meta: { title: "测评详情" }
  },
  {
    path: "/invite",
    name: "invite",
    component: () => import("@/views/invite/invite.vue"),
    meta: { title: "测评邀请" }
  },
  {
    path: "/invite/questionName",
    name: "inviteQuestionName",
    component: () => import("@/views/invite/questionName.vue"),
    meta: { title: "测评邀请" }
  },
  {
    path: "/health-record-infor",
    name: "healthrecordinfor",
    component: () => import("@/views/health/healthrecordinfor.vue"),
    meta: { title: "健康档案信息" }
  },
  {
    path: "/health-record",
    name: "health-record",
    component: () => import("@/views/health/health_record.vue"),
    meta: { title: "健康档案信息" }
  },
  {
    path: "/edit-record",
    name: "edit-record",
    component: () => import("@/views/health/edit_record.vue"),
    meta: { title: "完善档案信息" }
  },
  {
    path: "/check-record",
    name: "check-record",
    component: () => import("@/views/health/check_record.vue"),
    meta: { title: "查看基本信息" }
  },
  {
    path: "/informed-consent",
    name: "informedconsent",
    component: () => import("@/views/official/informedconsent.vue"),
    meta: { title: "心理咨询知情同意书" }
  }, //
  {
    path: "/questionInfor",
    name: "questionInfor",
    component: () => import("@/views/question/questionInfor.vue"),
    meta: { title: "问卷完成" }
  },
  {
    path: "/ai-guide",
    name: "guide",
    component: () => import("@/views/Guide/index"),
    meta: { title: "在线导医" }
  },
  {
    path: "/share-health",
    name: "sharehealth",
    component: () => import("@/views/shareHealth/sharehealth"),
    meta: { title: "创建健康档案" }
  },
  {
    path: "/infor-consummate",
    name: "inforconsummate",
    component: () => import("@/views/inforfile/inforconsummate"),
    meta: { title: "完善信息" } //登录后完善信息
  },
  {
    path: "/install",
    name: "install",
    component: () => import("@/views/personal/install"),
    meta: { title: "设置" }
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/appdownload",
    name: "appdownload",
    component: () => import("@/views/app/appdownload"),
    meta: { title: "海棠心悦App下载" }
  }, //下载C-BCTappdownloadcbct
  {
    path: "/appdownloadcbct",
    name: "appdownloadcbct",
    component: () => import("@/views/app/appdownloadcbct"),
    meta: { title: "下载C-BCT APP" }
  },
  {
    //公告栏list
    path: "/bull_list",
    name: "bull_list",
    component: () => import("@/views/personal/bull_list"),
    meta: { title: "公告栏" }
  },
  {
    //公告栏
    path: "/bulletinboard",
    name: "bulletinboard",
    component: () => import("@/views/personal/bulletinboard"),
    meta: { title: "公告栏" }
  },
  {
    //服药提醒
    path: "/diagnosis",
    name: "diagnosisList",
    component: () => import("@/views/diagnosis/diagnosis-list"),
    meta: { title: "服药提醒" }
  },
  {
    //服药提醒详情
    path: "/diagnosis-detail",
    name: "diagnosisDetail",
    component: () => import("@/views/diagnosis/diagnosis-detail"),
    meta: {
      title: "服药提醒详情",
      keepAlive: true
    }
  },
  {
    //服药提醒详情
    path: "/diagnosis-agree",
    name: "diagnosisAgree",
    component: () => import("@/views/diagnosis/agree"),
    meta: { title: "知情同意书" }
  },
  {
    //服药提醒详情
    path: "/doctor-comment",
    name: "doctorComment",
    component: () => import("@/views/doctor/comment"),
    meta: { title: "医生评论" }
  },
  {
    //咨询医生详情
    path: "/doctor-info",
    name: "doctorInfo",
    component: () => import("@/views/doctor/doctorInfo"),
    meta: { title: "医生主页" }
  },
  {
    //咨询医生详情
    path: "/doctor-appointment",
    name: "appointment",
    component: () => import("@/views/doctor/appointment"),
    meta: { title: "医生主页" }
  },
  {
    //知情同意书
    path: "/doctor-agree",
    name: "doctorAgree",
    component: () => import("@/views/doctor/agree"),
    meta: { title: "知情同意书" }
  },
  {
    //知情同意书
    path: "/doctor-yuyueok",
    name: "doctorYuyueok",
    component: () => import("@/views/doctor/yuyueok"),
    meta: { title: "知情同意书" }
  },
  {
    //我的预约
    path: "/my-appointment",
    name: "myAppointment",
    component: () => import("@/views/personal/appointment"),
    meta: { title: "我的预约" }
  },
  {
    //咨询窗口
    path: "/consult",
    name: "Consult",
    component: () => import("@/views/consult/index"),
    meta: { title: "咨询窗口" }
  }
  
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

export default router;
