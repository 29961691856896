import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import "babel-polyfill"; // 兼容360浏览器
import _ from "lodash";
import Vant from "vant";
import "vant/lib/index.css";
import "@vant/touch-emulator";
import FastClick from "fastclick";
import { wxsdk } from "./components/wx";
import preventReClick from "./utils/plugins";
import "core-js/stable";
import "regenerator-runtime/runtime";
Vue.prototype.wxsdk = wxsdk;
// Vue.prototype.preventReClick = preventReClick;

FastClick.attach(document.body);

Vue.use(Vant);
Vue.prototype._ = _;
Vue.prototype.mode = process.env.VUE_APP_MODE;
Vue.config.productionTip = false;
Vue.prototype.$PayUrl = "/";

Vue.config.ignoredElements = [
  // 忽略自定义元素标签抛出的报错
  "wx-open-launch-app",
  "wx-open-launch-weapp",
];

// const vConsole = require("@/utils/vconsole");

router.beforeEach(function (to, from, next) {
  document.title = to.meta.title || "\u200E";
  from.meta.keepAlive = to.path === "/healthyDetail";
  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
